import { Action, createReducer, on } from '@ngrx/store';

import { Profil } from '../..';
import { ProfilActions } from './profil.actions';

export const PROFIL_FEATURE_KEY = 'profil';

export interface ProfilPartialState {
  readonly [PROFIL_FEATURE_KEY]: ProfilState;
}

export interface ProfilState {
  isLoading: boolean;
  profil?: Profil;
}

export const initialState: ProfilState = {
  isLoading: false,
  profil: undefined
};

export const reducer = createReducer(
  initialState,

  on(
    ProfilActions.COMPONENT.loadInitial,
    ProfilActions.COMPONENT.reload,
    ProfilActions.COMPONENT.update,
    ProfilActions.COMPONENT.selectActiveOrganisation,

    (state): ProfilState => ({
      ...state,
      isLoading: true
    })
  ),

  on(
    ProfilActions.API.loadedInitialSuccessfully,
    ProfilActions.API.reloadedSuccessfully,

    (state, action): ProfilState => ({
      isLoading: false,
      profil: action.loadedProfil
    })
  ),

  on(
    ProfilActions.API.updatedSuccessfully,

    (state, action): ProfilState => ({
      isLoading: false,
      profil: action.updateResult
    })
  ),

  on(
    ProfilActions.COMPONENT.canceledUpdate,
    
    (state): ProfilState => ({
      ...state,
      isLoading: false
    })
  ),

  on(
    ProfilActions.API.selectedActiveOrganisationSuccessfully,
    
    (state, action): ProfilState => {
      const activeOrganisationId = action.organisationId;
      const activeOrganisation =
        state.profil!
          .organisationen
          .find(org => org.id === activeOrganisationId)!
          .name;

      return {
        profil: {
          ...state.profil!,
          activeOrganisationId,
          activeOrganisation
        },
        isLoading: false
      };
    }
  )
);

export function profilReducer(
  state: ProfilState | undefined,
  action: Action
): ProfilState {
  return reducer(state, action);
}
