import { PROFIL_FEATURE_KEY, ProfilState } from './profil.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getProfilState = createFeatureSelector<ProfilState>(PROFIL_FEATURE_KEY);

const IS_LOADING = createSelector(
  getProfilState,
  state => state.isLoading
);

const PROFIL = createSelector(
  getProfilState,
  state => state.profil
);

export const profilSelectQuery = {
  IS_LOADING,
  PROFIL
};
